<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "FitterCategories";
const ekURL = "/Salary/FitterCategories";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
        "FitterCategories endpointet dækker over løndele i E-Komplet. På endpointet kan man udtrække information om løndele i E-Komplet.",
        "Nedenstående kan man læse om hvilke værdier der kan udtrækkes igennem API'et."
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/:id`,
        },
      ],
      response: [
        {
          Select: false,
          ID: 2,
          ShowAbsenceCode: false,
          Reference: 111,
          Description: "Svendetimer",
          WorkTypeId: 0,
          Unit: "STK",
          IsOnInvoice: true,
          IncludeIllness: false,
          UpdateAllFittersHoursOnCategory: false,
          HourRate: 178.0,
          SocialFee: 49.0,
          BluegardenSalaryType: 581,
          VismaSalaryType: null,
          SalesPrice: 404.44,
          SalaryCompanyFitterCategory: "Normaltimer 3",
          SalaryCompanyUseAmount: false,
          SalaryCompanyUseRate: false,
          SalaryCompanyUseTotal: false,
          SalaryCompanyGroupByDate: false,
          LessorTypeID: null,
          LessorType: null,
          SalaryCompanyAbsenceCode: null,
          GroupFitterCategoriesWithSameSalaryCategory: false,
          Actions: null,
          Tilknyt: true,
          UnitID: 6,
        },
      ],
      attributes: [
        {
          name: "ID",
          desc: "Unique identifier of the fitter category.",
        },
        {
          name: "WorkTypeId",
          desc: "",
        },
        {
          name: "LessortypeID",
          desc: "",
        },
        {
          name: "UnitID",
          desc: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>