<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "FinancialPosts";
const ekURL = "/Accountancy/AllFinancialPosts";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
        "FinancialPosts endpointet dækker over finansposteringer i E-Komplet. På endpointet kan du hente finansposteringer ud af E-Komplet systemet."
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
      ],
      response: [
        {
          ID: 16293,
          ProjectID: 7068,
          InvoiceID: 8629,
          PurchaseOrderID: null,
          FinancialAccountID: 4285,
          Date: "2018-09-23T22:00:00Z",
          AppendixNumber: 251440,
          ProjectReference: "67895",
          Description: "Peter James inc., Nye armaturer i Herlev",
          Value: 21865.59,
          VatAmount: null,
          FinancialAccountName: "Peter James inc.",
          FinancialAccountNumber: 0,
          DimensionCarrierName: null,
          DimensionPurposeName: null,
          DimensionDepartmentName: "Randers",
          VatAccountName: null,
          Type: null,
          CurrencyCode: "DKK",
          CurrencyRate: 100.0,
          ForeignAmount: 21865.59,
          FileID: null,
          HasElectronicData: false,
          DateRangeStartDate: "0001-01-01T00:00:00Z",
          DateRangeEndDate: "0001-01-01T00:00:00Z",
        },
      ],
      attributes: [
        {
          name: "ID",
          desc: "Unique identifier of the financial-post.",
        },
        {
          name: "ProjectID",
          desc: "Unique identifier of the project associated with the financial post.",
        },
        {
          name: "InvoiceID",
          desc: "Unique identifier of the invoice associated with the financial post.",
        },
        {
          name: "PurchaseOrderID",
          desc: "Unique identifier of the purchase order associated with the financial post.",
        },
        {
          name: "FinancialAccountID",
          desc: "Unique identifier of the financial account associated with the financial post.",
        },
      ],
    };
  },
};
</script>
<style scoped>

</style>