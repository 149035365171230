<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "DepartmentDimensions";
const ekURL = "/Admin/Dimension";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/:id`,
        },
      ],
      response: [
        {
          ID: 1,
          DepartmentName: "Gravearbejde",
          Address: "Sygehusvej 5",
          Zip: "9000",
          City: "Aalborg",
          CountryName: "Danmark",
          Phone: "98101010",
          CVR: "12345678",
          ExternalName: "Sygehuset",
          InvoiceReportDocumentConfigurationName: "søren",
          InvoiceReportDocumentConfigurationID: 10,
          SubscriptionInvoiceReportDocumentConfigurationName: "",
          SubscriptionInvoiceReportDocumentConfigurationID: null,
          OnAccountReportDocumentConfigurationName: "",
          OnAccountReportDocumentConfigurationID: null,
          ReminderReportDocumentConfigurationName: "",
          ReminderReportDocumentConfigurationID: null,
          FinancialPostsReportDocumentConfigurationName: "",
          FinancialPostsReportDocumentConfigurationID: null,
          PercentageDocumentConfigurationName: "",
          PercentageReportDocumentConfigurationID: null,
          PercentageEmailTemplateConfigurationName: "",
          PercentageEmailTemplateConfigurationID: null,
          PercentageDraftEmailTemplateConfigurationName: "",
          PercentageDraftEmailTemplateConfigurationID: null,
          InvoiceProformaTemplateConfigurationName: "",
          InvoiceProformaTemplateConfigurationID: null,
          OnAccountProformaTemplateConfigurationName: "",
          OnAccountProformaTemplateConfigurationID: null,
          InvoiceEmailTemplateConfigurationName: "Salg 2",
          InvoiceEmailTemplateConfigurationID: 7,
          OnAccountEmailTemplateConfigurationName: "",
          OnAccountEmailTemplateConfigurationID: null,
          ReminderEmailTemplateConfigurationName: "",
          ReminderEmailTemplateConfigurationID: null,
          Actions: null,
        },
      ],
      attributes: [
        {
          name: "ID",
          desc: "Unique identifier of the deparment.",
        },
        {
          name: "InvoicereportDocumentConfigurationID",
          desc: "",
        },
        {
          name: "SubscriptionInvoiceReportDocumentConfigurationID",
          desc: "",
        },
        {
          name: "OnAccountReportDocumentConfigurationID",
          desc: "",
        },
        {
          name: "ReminderReportdocumentConfigurationID",
          desc: "",
        },
        {
          name: "FinancialPostsReportdocumentConfigurationID",
          desc: "",
        },
        {
          name: "PercentageReportDocumentConfigurationID",
          desc: "",
        },
        {
          name: "PercentageEmailTemplateConfigurationID",
          desc: "",
        },
        {
          name: "PercentageDraftEmailTemplateConfigurationID",
          desc: "",
        },
        {
          name: "InvoiceProformaTemplateConfigurationID",
          desc: "",
        },
        {
          name: "OnAccountProformaTemplateConfigurationID",
          desc: "",
        },
        {
          name: "InvoiceEmailTemplateConfigurationID",
          desc: "",
        },
        {
          name: "OnAccountEmailTemplateConfigurationID",
          desc: "",
        },
        {
          name: "ReminderEmailTemplateConfigurationID",
          desc: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>