<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
        <TextArea
          :textTitle="filteringTitle"
          :textLinesArray="filteringText"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "ClosedProjects";
const ekURL = "/Projects/AllClosed";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      filteringTitle: "Filtrering",
      filteringText: ["På dette endpoint kan man påføre filtrering med parameterne, <code>fromDate</code> og <code>toDate</code>. Hvis der ikke er påført filter vil der påføres et default filter, hvor <code>fromDate</code> er datoen 2000/01/01 og <code>toDate</code> er datoen 2030/01/01."],
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
        "ClosedProjects endpointet dækker over lukkede sager i E-Komplet. På endpointet kan man trække lukkede sager ud af E-Komplet systemet.",
        "Nedenstående kan man læse om hvilke værdier der kan trækkes ud igennem API'et."
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/:id`,
        },
      ],
      response: [
        {
          ID: 7270,
          ProjectID: 7270,
          DebtorFinancialAccountID: 19,
          NetLabor: 0,
          OverTime: 0,
          ZoneAllowances: 0,
          TotalPurchases: 0,
          TotalLabor: 0,
          ProjectType: "MiniProject",
          IsInternal: false,
          IsWorkInProgress: true,
          ProjectGroupName: "Sprinkler Hospital",
          Name: "Udskift lamper",
          ProjectReference: "68070",
          Responsible: "PA1",
          RequisitionNumber: null,
          Description: "Udskift lamper",
          Address: "Strandvænget 7 *",
          Zip: "4400 *",
          DebtorReference: 35353535,
          Budget: 0.0,
          RemainingBudget: 4928.65,
          DebtorName: "Jane Steffensen",
          StartDate: "2019-04-09T22:00:00Z",
          EndDate: "2019-04-22T22:00:00Z",
          TotalPOCost: 2348.0,
          TotalLaborCost: 642.76,
          HoursFitterHour: 2.5,
          Billed: 7919.41,
          Margin: 4928.65,
          ActualMarginPrHour: 1971.46,
          Coverage: 62.23506549098986,
          Carrier: null,
          Department: "Udland",
          Purpose: null,
          Seller: null,
          Teamleader: null,
          ReopenLink: true,
          DetailsLink: true,
          Actions: null,
          DateRangeStartDate: "0001-01-01T00:00:00Z",
          DateRangeEndDate: "0001-01-01T00:00:00Z",
        },
      ],
      attributes: [
        {
          name: "ID",
          desc: "Unique identifier of the project.",
        },
        {
          name: "ProjectID",
          desc: "Unique identifier of the project.",
        },
        {
          name: "DebtorFinancialAccountID",
          desc: "Unique identifier of the finanical account of the debtor.",
        },
        {
          name: "ResponibleID",
          desc: "Unique identifier for the user responsible for the project.",
        },
        {
          name: "CarrierID",
          desc: "",
        },
        {
          name: "PurposeID",
          desc: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>