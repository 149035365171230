<template>
  <v-card class="pb-4 code-card">
    <notification notification-text="Copied Code!" />
    <v-card-text class="code-header">
      <h4 class="code-title">
        {{ cardTitle }}
        <CopyButton class="copyBtn" :textToCopy="jsonPostExample" />
      </h4>
    </v-card-text>
    <pre>
<code style="background-color: inherit">{{postExample}}</code>
</pre>
  </v-card>
</template>
<script>
import CopyButton from "../buttons/CopyButton.vue";
import Notification from "../notification/Notification.vue";

export default {
  components: {
    CopyButton,
    Notification,
  },
  props: ["cardTitle", "postExample"],
  data() {
    return {
      jsonPostExample: JSON.stringify(this.postExample),
    };
  },
};
</script>
<style scoped>
.code-card {
  background: #4f566b;
}
.code-header {
  background: #3c4257;
}
.code-title {
  color: #81d494;
}
.copyBtn {
  float: right;
}
pre {
  padding-left: 10px;
  color: #e0e6ec;
}
code {
  white-space: pre-wrap;
}
</style>