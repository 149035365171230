<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
        <TextArea
          :textTitle="filteringTitle"
          :textLinesArray="filteringText"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "SalaryPeriods";
const ekURL = "/Salary/SalaryPeriods";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      filteringTitle: "Filtrering",
      filteringText: ["På dette endpoint kan man påføre filtrering med parameterne, <code>fromDate</code> og <code>toDate</code>."],
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
        "SalaryPeriods endpointet dækker over lønperioder i E-Komplet systemet. På endpointet kan man udtrække lønperioder fra E-Komplet.",
        "Nedenstående kan man læse om hvilke værdier man kan udtryække igennem API'et."
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/:id`,
        },
      ],
      response: [
        {
          ID: 220,
          Reference: 17,
          Type: "14 dage",
          Description: "Uge 39 til uge 41",
          FromDate: "2018-09-24T22:00:00Z",
          ToDate: "2018-10-07T22:00:00Z",
          CreationDate: "2018-07-02T22:00:00Z",
          BluegardenReference: null,
          IsFinalized: false,
          Actions: null,
        },
      ],
      attributes: [
        {
          name: "ID",
          desc: "Unique identifier of the salaryperiod.",
        },
        {
          name: "Reference",
          desc: "",
        },
        {
          name: "Type",
          desc: "Describes the type of salary period.",
        },
        {
          name: "Description",
          desc: "A text describing the salary period.",
        },
        {
          name: "FromDate",
          desc: "Start date of the salary period.",
        },
        {
          name: "ToDate",
          desc: "End date of the salary period.",
        },
        {
          name: "CreationDate",
          desc: "The date that the salary period was created.",
        },
        {
          name: "BlueGardenReference",
          desc: "Describes if the salary period has a reference to the company BlueGarden.",
        },
        {
          name: "IsFinalized",
          desc: "",
        },
        {
          name: "Actions",
          desc: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>