<template>
  <div>
    <div class="container">
      <TextArea
        class="child"
        :textTitle="endpointName"
        :textLinesArray="endpointDesc"
      ></TextArea>
      <EndpointCard
        class="child"
        cardTitle="ENDPOINTS"
        :endpoints="endpoints"
      />
      <TextArea
        class="child"
        :textTitle="filteringTitle"
        :textLinesArray="filteringText"
      ></TextArea>
    </div>
    <div :ref="endpoints[0].ref" class="container">
      <Attribute
        class="child"
        :attributes="attributes"
        :endpoint="responseTitle"
      />
      <ResponseCard
        class="child"
        :response="response"
        :cardTitle="responseCodeTitle"
      />
    </div>
    <div :ref="endpoints[2].ref" class="container">
      <div class="child">
        <TextArea
          :textTitle="postTitle"
          :textLinesArray="calendarEventPostDesc"
        ></TextArea>
        <Attribute :attributes="calendarEventDTO" endpoint="CalendarEventDTO" />
      </div>
      <div class="child">
        <PostExample
          class="postCard"
          :postExample="calendarEventPostExample"
          cardTitle="POST /api/v3.0/calendarevents"
        />
        <ResponseCard class="mt-5" response="" cardTitle="RESPONSE." />
      </div>
    </div>
  </div>
</template>
<style scoped>
.postCard {
  max-height: 600px;
}

.container {
  display: flex;
  flex-flow: row wrap;
  padding-left: 10rem;
  padding-right: 10rem;
}

.child {
  min-width: 40%;
  max-width: 45%;
  flex-grow: 1;
  flex-shrink: 1;
}

@media screen and (max-width: 500px) {
  .container {
    flex-flow: column wrap;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .child {
    max-width: 380px;
    min-width: 300px;
  }
}
</style>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";
import PostExample from "../../components/request/PostExample.vue";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
    PostExample,
  },
  created() {
    this.$root.$on("scrollToEndpoint", (ref) => {
      var element = this.$refs[ref];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    });
  },
  computed: {
    postTitle() {
      return this.$t("calendarevents.postTitle");
    },
    endpointName() {
      return this.$t("calendarevents.endpointTitle");
    },
    calendarEventDTO() {
      return this.$t("calendarevents.postAttributes");
    },
    calendarEventPostDesc() {
      return this.$t("calendarevents.postDesc");
    },
    calendarEventPostExample() {
      return this.$t("calendarevents.postExample");
    },
    filteringTitle() {
      return this.$t("calendarevents.filteringTitle");
    },
    filteringText() {
      return this.$t("calendarevents.filteringText");
    },
    responseTitle() {
      return this.$t("calendarevents.getTitle");
    },
    responseCodeTitle() {
      return this.$t("calendarevents.responseCodeTitle");
    },
    endpointDesc() {
      return this.$t("calendarevents.endpointDesc");
    },
    endpoints() {
      return this.$t("calendarevents.controllerEndpoints");
    },
    response() {
      return this.$t("calendarevents.getResponse");
    },
    attributes() {
      return this.$t("calendarevents.getAttributes");
    },
  },
};
</script>
