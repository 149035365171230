<template>
  <v-card style="width: 90%" class="pb-4" elevation="0">
    <v-card-title>{{ endpoint }}</v-card-title>
    <h4 class="pa-1">Attributes</h4>
    <v-divider></v-divider>

    <div v-bind:key="index" v-for="(item, index) in attributes">
      <code
        class="pa-1"
        style="background-color: transparent; color: #3c4257"
        >{{ item.name }}</code
      >
      <code style="background-color: transparent; color: #919baa">{{
        item.type
      }}</code>
      <code
        style="background-color: transparent; color: #f93e3e"
        v-if="item.required"
        >REQUIRED</code
      >
      <code
        style="background-color: transparent; color: #8c97a6"
        v-if="item.required == false"
        >optional</code
      >
      <p v-if="item.default !== undefined"></p>
      <code v-if="item.default !== undefined" class="pa-1" style="background-color: transparent; color: #3c4257"
        >default:</code
      >
      <code style="background-color: transparent; color: #919baa">{{
        item.default
      }}</code>
      <p class="pa-1" style="font-size: 0.8rem">{{ item.desc }}</p>
      <v-divider></v-divider>
    </div>
  </v-card>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: ["attributes", "endpoint"],
};
</script>