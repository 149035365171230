<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="errorHandlingTitle"
          :textLinesArray="errorHandlingText"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div class="pt-5">
          <status-code-card class="mb-5" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="responseTitle"
          :textLinesArray="responseText"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div>
          <ResponseCard
            :response="responsePostDTO"
            cardTitle="POST RESPONSE DTO"
          />
          <ResponseCard
            class="mt-5"
            :response="responseGetDTO"
            cardTitle="GET RESPONSE DTO"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ResponseCard from "../components/request/ResponseCard.vue";
import TextArea from "../components/content/TextArea.vue";
import StatusCodeCard from "../components/request/StatusCodeCard.vue";

export default {
  components: {
    StatusCodeCard,
    ResponseCard,
    TextArea,
  },
  computed: {
    responseGetDTO() {
      return this.$t("response.getResponseDTO");
    },
    responsePostDTO() {
      return this.$t("response.postResponseDTO");
    },
    responseTitle() {
      return this.$t("response.responseTitle");
    },
    responseText() {
      return this.$t("response.responseText");
    },
    errorHandlingTitle() {
      return this.$t("response.errorHandlingTitle");
    },
    errorHandlingText() {
      return this.$t("response.errorHandlingText");
    },
  },
};
</script>
<style scoped>
</style>