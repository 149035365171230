<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "Creditors";
const ekURL = "/Accountancy/Creditors";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
        "Creditors endpointet dækker over kreditorer i E-Komplet. På endpointet kan man hente kreditorer i E-Komplet systemet.",
        "Nedenstående kan man læse om hvilke værdier der kan hentes ud igennem API'et."
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/:id`,
        },
      ],
      response: [
        {
          ID: 5,
          Select: true,
          DepartmentID: 1,
          CreditorFinancialAccountID: 12,
          ShortName: "NA",
          CreditorBankAgreementID: 2,
          WholesalerPriority: 2,
          OldCreditorReference: "96314510",
          CreditorReference: "96314510",
          CreditorName: "BD",
          Association_Address: "Håndværkervej 8",
          Association_Address2: null,
          ZipCodeID: null,
          Zip: null,
          City: null,
          Country: null,
          AddressNote: null,
          Latitude: null,
          Longitude: null,
          MetaDataID: null,
          Association_Phone: "96314510",
          DimensionCarrierId: null,
          Carrier: null,
          DimensionPurposeId: null,
          Purpose: null,
          DimensionDepartmentId: null,
          Department: null,
          Association_Name: "BD",
          Association_Email: null,
          CreditorNote: null,
          EmailElectronic: "madsboell@gmail.com",
          EAN: null,
          CVR: null,
          PaymentTermsId: 3,
          BankAgreement: "Bankaftale",
          PaymentTerms: "Løbende måned + 14 dage",
          CreditorBalance: -51760.68,
          ForeignBalance: -51760.68,
          Currency: "DKK",
          CreditorPaymentType: "Bankoverførsel",
          RegistrationNumber: "2222",
          AccountNumber: "3333333333",
          FINumber: null,
          IBAN: null,
          SWIFT: null,
          VatZoneId: 1,
          MasterFinancialAccountID: null,
          MasterFinancialAccountName: "",
          MasterFinancialAccountNumber: null,
          VatZone: "Indland",
          UseGroupPayment: false,
          HasAccountancyPosts: true,
          ShowPurchaseInvoices: true,
          AutoPostElectronicDocuments: true,
          UsePaperflow: false,
          Actions: null,
          CreditorPosts: true,
          PersonID: 40,
          AddressID: 217,
          ChangePerson: false,
          ChangeAddress: false,
          DocumentCount: 0,
        },
      ],
      attributes: [
        {
          name: "ID",
          desc: "Unique identifier of the creditor.",
        },
        {
          name: "DepartmentID",
          desc: "Unique identifier of the department associated with the creditor.",
        },
        {
          name: "CreditorFinancialAccountID",
          desc: "Unique identifier of the financial account associated with the creditor.",
        },
        {
          name: "CreditorBankAgreementID",
          desc: "",
        },
        {
          name: "ZipCodeID",
          desc: "Unique identifier of the zipcode.",
        },
        {
          name: "MetaDataID",
          desc: "",
        },
        {
          name: "DimensionCarrierID",
          desc: "",
        },
        {
          name: "DimensionPurposeId",
          desc: "",
        },
        {
          name: "DimensionDepartmentId",
          desc: "",
        },
        {
          name: "PaymentTermsId",
          desc: "",
        },
        {
          name: "VatZoneId",
          desc: "",
        },
        {
          name: "MasterFinancialAccountID",
          desc: "",
        },
        {
          name: "PersonID",
          desc: "",
        },
        {
          name: "AddressID",
          desc: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>