<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="textTitle"
          :textLinesArray="introductionText"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div>
          <p>{{ $t("versioning.code-card.header") }}</p>
          <CodeCard
            :cardTitle="cardTitle"
            cardCode="https://externalaccessapi.e-komplet.dk/api/v3.0/ENDPOINT"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CodeCard from "../components/content/CodeCard.vue";
import TextArea from "../components/content/TextArea.vue";

export default {
  components: {
    CodeCard,
    TextArea,
  },
  computed: {
    textTitle() {
      return this.$t("versioning.title");
    },
    introductionText() {
      return this.$t("versioning.text");
    },
    cardTitle() {
      return this.$t("versioning.code-card.title");
    },
  },
};
</script>
<style scoped>
</style>