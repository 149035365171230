<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1200px">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" fab v-on="on" class="ma-3" center link>
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-outline </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Tilføj / ændre endpoint</v-list-item-title>
        </v-list-item>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Tilføj / ændre endpoint</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="endpointIdsSelected"
                  :items="endpointDropdown"
                  :menu-props="{ maxHeight: '400' }"
                  label="Select"
                  multiple
                  hint="Vælg Endpoint"
                  persistent-hint
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="endpointAccessModifiers"
                  :items="endpointAccessList"
                  :menu-props="{ maxHeight: '400' }"
                  label="Select"
                  multiple
                  hint="Vælg Adgangsniveau"
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6"> </v-col>
              <v-col cols="12" sm="6">
                <v-container class="px-0" fluid>
                  <v-switch
                    v-model="accessAll"
                    :label="`Adgang til alt på endpoint: ${accessAll.toString()}`"
                  ></v-switch>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onSubmit(), (dialog = false)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  mounted() {
    this.populateEndpointsDropdown();
    this.fetchEndpointAccessForCustomerOnSite();
  },
  data: () => ({
    endpointAccessList: [
      {
        value: 1,
        text: "Kan Oprette (POST)",
      },
      {
        value: 2,
        text: "Kan Hente (GET)",
      },
      {
        value: 3,
        text: "Kan Slette (DELETE)",
      },
      {
        value: 4,
        text: "Kan Opdatere (PUT)",
      },
    ],
    accessAll: false,
    dialog: false,
    endpointIdsSelected: [],
    endpointsResponse: [],
    endpointDropdown: [],
    endpointAccessModifiers: [],
  }),
  methods: {
    async fetchEndpointAccessForCustomerOnSite() {
      await axios.get(
        process.env.VUE_APP_BASEURL_EXTERNALAPI +
          `/Admin/endpoints/${this.$route.params.id}/${this.$route.params.siteId}`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("EK-Token3"),
          },
        }
      );
    },
    populateEndpointsDropdown() {
      axios
        .get(process.env.VUE_APP_BASEURL_EXTERNALAPI + "/Admin/endpoints", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("EK-Token3"),
          },
        })
        .then((response) => {
          this.endpointsResponse = response.data.data;
          this.endpointDropdown = this.endpointsResponse.map((endpoint) => ({
            value: endpoint.id,
            text: endpoint.endpointPath,
          }));
        });
    },
    async addEndpointAccess(request) {
      console.log(request);
      await axios
        .post(
          process.env.VUE_APP_BASEURL_EXTERNALAPI +
            "/Admin/AddEndpointAccessToCustomerOnSite",
          request,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("EK-Token3"),
            },
          }
        )
        .then(() => {
          this.$root.$emit("endpointAccessAdded");
        });
    },
    async onSubmit() {
      let addEndpointRequest = [];

      this.endpointIdsSelected.forEach((endpointId) => {
        let addAccessDTO = {};
        addAccessDTO.EndpointID = endpointId;
        addAccessDTO.SiteID = parseInt(this.$route.params.siteId);
        addAccessDTO.CustomerID = this.$route.params.id;
        this.endpointAccessModifiers.forEach((accessModifier) => {
          if (accessModifier == 1) {
            addAccessDTO.AllowPOST = true;
          } else if (accessModifier == 2) {
            addAccessDTO.AllowGET = true;
          } else if (accessModifier == 3) {
            addAccessDTO.AllowDELETE = true;
          } else if (accessModifier == 4) {
            addAccessDTO.AllowPUT = true;
          }
        });
        if (this.accessAll) {
          addAccessDTO.AllowPOST = true;
          addAccessDTO.AllowDELETE = true;
          addAccessDTO.AllowGET = true;
          addAccessDTO.AllowPUT = true;
        }
        console.log(addAccessDTO);
        addEndpointRequest.push(addAccessDTO);
      });

      await this.addEndpointAccess(addEndpointRequest);
    },
  },
};
</script>