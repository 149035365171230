<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
        <TextArea
          :textTitle="filteringTitle"
          :textLinesArray="filteringText"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "InvoiceLines";
const ekURL = "/Accountancy/InvoiceLines";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      filteringTitle: "Filtrering",
      filteringText: ["På dette endpoint kan man påføre filtrering med parameterne, <code>fromDate</code> og <code>toDate</code>. Hvis der ikke er påført filter vil der påføres et default filter, hvor <code>fromDate</code> er datoen 2000/01/01 og <code>toDate</code> er datoen 2030/01/01."],
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
        "InvoiceLines dækker fakturalinjer i E-Komplet systemet. På endpointet kan du udtrykke fakturalinjer som ligger i E-Komplet systemet.",
        "Nedenstående kan man læse om hvilke værdier man kan trække ud igennem API'et."
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
      ],
      response: [
        {
          ID: 13308,
          DebtorReference: "0",
          DebtorName: "Peter James inc.",
          AppendixNumber: 251440,
          Date: "2018-09-23T22:00:00Z",
          ProjectID: 7068,
          ProjectReference: "67895",
          ProjectDescription: "Nye armaturer i Herlev",
          DebtorFinancialAccountID: 4285,
          DebtorID: 3237,
          ItemCode: "-",
          ItemName: "Svendetimer",
          CatalogName: "",
          CatalogItemGroupName: "",
          Amount: 10.5,
          UnitName: "T",
          UnitPrice: 404.44,
          Discount: 0.0,
          TotalPrice: 4246.62,
          RequisitionNumber: "1548",
          DimensionDepartment: "Randers",
          DimensionDepartmentID: 11,
          DimensionCarrier: "",
          DimensionCarrierID: null,
          DimensionPurpose: "",
          DimensionPurposeID: null,
          CurrencyCode: "DKK",
          CurrencyRate: 100.0,
          ForeignAmount: 4246.62,
          ForeignUnitPrice: 404.44,
          DateRangeStartDate: "2018-09-23T22:00:00Z",
          DateRangeEndDate: "2018-09-23T22:00:00Z",
        },
      ],
      attributes: [
        {
          name: "ID",
          desc: "Unique identifier of the invoiceline.",
        },
        {
          name: "DimensionDepartmentID",
          desc: "",
        },
        {
          name: "DimensionCarrierID",
          desc: "",
        },
        {
          name: "DimensionPurposeID",
          desc: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>