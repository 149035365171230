<template>
  <div>
    <v-navigation-drawer app right permanent expand-on-hover width="356px">
      <v-list nav dense>
        <add-customer-button />
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="showCustomerAddedAlert" max-width="500px">
      <v-card >
        <v-card-title>
          <span class="headline">The Customer was added successfully</span>
        </v-card-title>
        <v-card-actions>
          <v-btn text @click="showCustomerAddedAlert = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="customers"
      v-model="selected"
      item-key="id"
      class="elevation-1 mr-10 ml-10"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4">
      </v-text-field>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="clickRow(item)">
          mdi-magnify
        </v-icon>
        <v-icon small class="mr-2" @click="toggleApiAccess(item)">
          {{ item.apiAccess ? "mdi-lock-open-check" : "mdi-lock-open-remove" }}
        </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import AddCustomerButton from "../../components/buttons/AddCustomerButton.vue";
import axios from "axios";

export default {
  components: {
    AddCustomerButton,
  },
  mounted() {
    this.fetchCustomers();
  },
  created() {
    this.$root.$on("customerAdded", () => {
      this.showCustomerAddedAlert = true;
      setTimeout(() => {
        this.showCustomerAddedAlert = false;
      }, 1500);
      this.fetchCustomers();
    });
  },
  data() {
    return {
      selected: [],
      search: "",
      customers: [],
      showCustomerAddedAlert: false,
    };
  },
  computed: {
    headers() {
      return [
        { text: "API Key", value: "apiKey" },
        { text: "Customer", value: "customerName" },
        { text: "Key Valid Until", value: "validUntil" },
        { text: "Created by", value: "createdByUser" },
        { text: "Access All", value: "accessAll" },
        { text: "API Access " , value: "apiAccess" },
        { text: "Contact", value: "contactName"},
        { text: "Actions,", value: "action" },
      ];
    },
  },
  methods: {
    fetchCustomers() {
      axios
        .get(process.env.VUE_APP_BASEURL_EXTERNALAPI + "/admin/customers", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("EK-Token3"),
          },
        })
        .then((response) => {
          this.customers = response.data.data;
        });
    },
    clickRow: function (item) {
      this.$router.push({
        path: `customers/${item.customerID}/${item.customerName}`,
      });
    },
    toggleApiAccess: function(customer) {
      const confirmationMessage = customer.apiAccess
          ? `Are you sure you want to revoke API access for ${customer.customerName}?`
          : `Are you sure you want to grant API access for ${customer.customerName}?`;

      if (!confirm(confirmationMessage)) {
        return;
      }
      axios
        .post(
          process.env.VUE_APP_BASEURL_EXTERNALAPI + "/admin/toggleCustomerApiAccess",
          {
            customerID: customer.customerID,
            apiAccess: !customer.apiAccess,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("EK-Token3"),
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            customer.apiAccess = !customer.apiAccess;
          }
        })
        .catch(() => {
          alert("There was an error while toggling the customer's API access.");
        });
    },
    filterOnlyCapsText(value, search) {
      search = search.toLocaleUpperCase();
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
    deleteItem(item){
      let text = "Press a button!\nEither OK or Cancel.";
  if (confirm(text) == true) {
    let externalCustomerDTOs = [
      {
        customerName : item.customerName,
        contactName: item.contactName,
        contactEmail: item.contactEmail,
        contactPhone: item.contactPhone,
        validUntil: item.validUntil,
        CreatedByUser: item.createdByUser,
        APIKey: item.apiKey,
        CustomerID : item.customerID,
        AccessAll: item.AccessAll,
      }
    ]
    axios
    .post(process.env.VUE_APP_BASEURL_EXTERNALAPI + "/admin/DeleteExternalCustomers",
    externalCustomerDTOs,
        {
    headers: {
            Authorization: "Bearer " + localStorage.getItem("EK-Token3"),
          },
        })
        .then((response) => {
          if(response.status == 200){
            alert(`You deleted item with Name: ${item.customerName}`);
            this.fetchCustomers()
          }
          
        }, (error) => {
          alert('Error occurred when trying to delete item.')
          console.log(error)
        });
  } else {
	alert('You did NOT delete the item!');
  }
    },
  },
};
</script>
<style scoped>
</style>