<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
        <TextArea
          :textTitle="filteringTitle"
          :textLinesArray="filteringText"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "Fitters";
const ekURL = "/Fitters/FittersOverview";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      filteringTitle: "Filtrering",
      filteringText: ["På dette endpoint kan der filtreres med parameteren <code>fromDate</code>."],
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
        "Fitters endpointet dækker over montører i E-Komplet. På endpointet kan du udtrække montører fra E-Komplet systemet.",
        "Nedenstående kan man læse om hvilke værdier man kan trække ud af endpointet igennem API'et."
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/:id`,
        },
      ],
      response: [
        {
          ID: 6,
          OldReference: "199",
          Name: "Admin",
          Phone: "Hustru Lone 45454545 - ",
          JobPosition: "Montør ",
          SalaryID: "1",
          StartDate: "2020-01-27T23:00:00Z",
          EndDate: "2020-07-29T21:59:00Z",
          Username: "system admin",
          Note: null,
          Email: null,
          IsPlannable: false,
          SortingKey: null,
          ShowInHourSummaries: false,
          IncludeInExport: true,
          SalaryPeriodTypeID: 2,
          SalaryPeriodTypeName: "Måned",
          IsSalesPerson: false,
          Actions: null,
          ShowFitterRates: true,
          ShowFitterCategoryConfiguration: true,
          Locations: [],
        },
      ],
      attributes: [
        {
          name: "ID",
          desc: "Unique identifier of the fitter.",
        },
        {
          name: "SalaryID",
          desc: "Unique identifier of the salary object of the fitter.",
        },
        {
          name: "SalaryPeriodTypeID",
          desc: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>