<template>
  <div>
    <v-navigation-drawer app right permanent expand-on-hover width="356px">
      <v-list nav dense>
        <add-site-customer-button />
      </v-list>
    </v-navigation-drawer>
    <h1 class="ml-10 pb-10 pt-10">Kunde: {{ customerName }}</h1>
    <v-data-table
      :headers="headers"
      :items="sitesData"
      v-model="selected"
      item-key="id"
      class="elevation-1 mr-10 ml-10 mt-10"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="rowClick(item)">
          mdi-magnify
        </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import axios from "axios";
import AddSiteCustomerButton from "../../components/buttons/AddSiteCustomerButton.vue";

export default {
  components: {
    AddSiteCustomerButton,
  },
  mounted() {
    this.fetchSitesForCustomer();
  },
  data() {
    return {
      customerName: this.$route.params.name,
      selected: [],
      search: "",
      sitesData: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "RecordId",
          value: "recordId",
        },
        {
          text: "Side",
          value: "baseUrl",
        },
        { text: "Actions", value: "action" },
      ];
    },
  },
  created() {
    this.$root.$on("siteAdded", () => {
      this.fetchSitesForCustomer();
    });
  },
  methods: {
    fetchSitesForCustomer() {
      axios
        .get(
          process.env.VUE_APP_BASEURL_EXTERNALAPI +
            `/Admin/customers/${this.$route.params.id}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("EK-Token3"),
            },
          }
        )
        .then((response) => (this.sitesData = response.data.data));
    },
    rowClick: function (item) {
      this.$router.replace({ path: `site/${item.recordId}` });
    },
    filterOnlyCapsText(value, search) {
      search = search.toLocaleUpperCase();
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
  },
};
</script>
<style scoped>
</style>