<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1200px">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" fab v-on="on" class="ma-3" center link>
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-outline </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Tilføj side-adgang til kunde</v-list-item-title>
        </v-list-item>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Tilføj side-adgang til kunde</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete
                    v-model="selectedSiteIDs"
                    :items="siteSelecter"
                    label="Select"
                    hint="Vælg sites"
                    persistent-hint
                    multiple
                    clearable
                    chips
                    deletable-chips
                    small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-container class="px-0" fluid>
                  <v-switch
                    v-model="accessAll"
                    :label="`Adgang til alle endpoints: ${accessAll.toString()}`"
                  ></v-switch>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="onSubmit(), (dialog = false)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  mounted() {
    axios
      .get(process.env.VUE_APP_BASEURL_EXTERNALAPI + "/admin/sites", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("EK-Token3"),
        },
      })
      .then((response) => {
        this.sitesData = response.data.data;
        this.siteSelecter = this.sitesData.map((site) => ({
          value: {
            ExternalCustomerID: 0,
            ActiveSiteID: site.recordId,
          },
          text: site.name,
        }));
      });
  },
  data: () => ({
    validUntil: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    customerName: "",
    createdBy: "",
    accessAll: false,
    dialog: false,
    selectedSiteIDs: [],
    sitesData: [],
    siteSelecter: [],
  }),
  methods: {
    onSubmit() {
      this.selectedSiteIDs.forEach((dto) => {
        dto.ExternalCustomerID = this.$route.params.id;
      });

      axios
        .post(
          process.env.VUE_APP_BASEURL_EXTERNALAPI +
            "/Admin/AddSiteAccessToExternalCustomer",
          this.selectedSiteIDs,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("EK-Token3"),
            },
          }
        )
        .then(() => {
          this.$root.$emit("siteAdded");
        });
    },
  },
};
</script>