<template>
  <v-card color="#f7fafc" class="pb-4">
    <v-card-text style="background: #e3e8ee">
      <h4 style="color: #8992a2">HTTP STATUS CODES</h4>
    </v-card-text>
    <div class="pl-6">
      <v-row no-gutters>
        <v-col cols="12" sm="6" md="4">
          <v-card color="#f7fafc" class="pa-2" elevation="0">
            <h5 style="color: #4f566b">{{ this.$t("statusCodes.200") }}</h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="8">
          <v-card color="#f7fafc" class="pa-2" elevation="0">
            <div style="font-size: 0.9rem">
              {{ this.$t("statusCodes.200desc") }}
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card color="#f7fafc" class="pa-2" elevation="0">
            <h5 style="color: #4f566b">{{ this.$t("statusCodes.400") }}</h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="8">
          <v-card color="#f7fafc" class="pa-2" elevation="0">
            <div style="font-size: 0.9rem">
              {{ this.$t("statusCodes.400desc") }}
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card color="#f7fafc" class="pa-2" elevation="0">
            <h5 style="color: #4f566b">{{ this.$t("statusCodes.401") }}</h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="8">
          <v-card color="#f7fafc" class="pa-2" elevation="0">
            <div style="font-size: 0.9rem">
              {{ this.$t("statusCodes.401desc") }}
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card color="#f7fafc" class="pa-2" elevation="0">
            <h5 style="color: #4f566b">{{ this.$t("statusCodes.404") }}</h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="8">
          <v-card color="#f7fafc" class="pa-2" elevation="0">
            <div style="font-size: 0.9rem">
              {{ this.$t("statusCodes.404desc") }}
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card color="#f7fafc" class="pa-2" elevation="0">
            <h5 style="color: #4f566b">{{ this.$t("statusCodes.500") }}</h5>
          </v-card>
        </v-col>
        <v-col cols="6" md="8">
          <v-card color="#f7fafc" class="pa-2" elevation="0">
            <div style="font-size: 0.9rem">
              {{ this.$t("statusCodes.500desc") }}
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
export default {};
</script>