<template>
  <v-app-bar app color="transparent" elevation="0" clipped>
    <v-app-bar-nav-icon
      class="hidden-md-and-up"
      @click="toggleSidebar"
    ></v-app-bar-nav-icon>
    <v-spacer> </v-spacer>
    <v-btn
      v-if="!isLoggedIn"
      color="transparent"
      elevation="0"
      @click="setLocale"
    >
      <v-img
        v-if="getLocale() == 'en'"
        lazy-src="@/assets/dk.png"
        max-height="25"
        max-width="50"
        src="@/assets/dk.png"
      ></v-img>
      <v-img
        v-if="getLocale() == 'da'"
        lazy-src="@/assets/gb.png"
        max-height="25"
        max-width="50"
        src="@/assets/gb.png"
      ></v-img>
    </v-btn>
  </v-app-bar>
</template>
<script>
export default {
  data() {
    return {
      locale: this.$i18n.locale,
    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },
  methods: {
    toggleSidebar() {
      this.$root.$emit("toggle-side-bar");
    },
    async setLocale() {
      if (this.$i18n.locale == "da") {
        this.$root.$i18n.locale = "en";
        this.locale = "en";
      } else {
        this.$root.$i18n.locale = "da";
        this.locale = "da";
      }
      this.$root.$emit("reloadPage");
    },
    getLocale() {
      return this.locale;
    },
  },
};
</script>
<style scoped>
</style>