<template>
  <v-alert
    class="notification mb-5 mr-5"
    border="top"
    colored-border
    color="green"
    type="info"
    elevation="2"
    :value="alert"
    transition="scale-transition"
  >
    {{ notificationText }}
  </v-alert>
</template>
<script>
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export default {
  data() {
    return {
      alert: false,
    };
  },
  created() {
    this.$root.$on("notify", async () => {
      this.alert = true;
      await delay(3000);
      this.alert = false;
    });
  },
  props: ["notificationText"],
};
</script>

<style scoped>
.notification {
  min-width: 400px;
  position: fixed;
  z-index: 999;
  right: 0;
  bottom: 0;
}
</style>