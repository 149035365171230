<template>
  <div>
    <div class="container">
      <TextArea
        class="child"
        :textTitle="endpointName"
        :textLinesArray="endpointDesc"
      ></TextArea>
      <EndpointCard
        class="child"
        cardTitle="ENDPOINTS"
        :endpoints="endpoints"
      />
      <TextArea
        class="child"
        :textTitle="filteringTitle"
        :textLinesArray="filteringText"
      ></TextArea>
    </div>
    <div ref="GET " class="container">
      <Attribute
        class="child"
        :attributes="attributes"
        :endpoint="responseTitle"
      />
      <ResponseCard
        class="child"
        :response="response"
        :cardTitle="responseCodeTitle"
      />
    </div>
    <div ref="POST" class="container">
      <div class="child">
        <TextArea :textTitle="postTitle" :textLinesArray="postDesc"></TextArea>
        <div>
          <Attribute :attributes="postAttributes" endpoint="WorksheetsDTO" />
          <Attribute
            :attributes="postAttributes_1"
            endpoint="AssociationAddressDTO"
          />
        </div>
      </div>
      <div class="child">
        <PostExample
          :postExample="postExample"
          cardTitle="POST /api/v3.0/worksheets"
        />
        <ResponseCard
          class="mt-5"
          :response="postResponse"
          cardTitle="RESPONSE."
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.postCard {
  max-height: 600px;
}

.container {
  display: flex;
  flex-flow: row wrap;
  padding-left: 10rem;
  padding-right: 10rem;
}

.child {
  min-width: 40%;
  max-width: 45%;
  flex-grow: 1;
  flex-shrink: 1;
}

@media screen and (max-width: 500px) {
  .container {
    flex-flow: column wrap;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .child {
    max-width: 380px;
    min-width: 300px;
  }
}
</style>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";
import PostExample from "../../components/request/PostExample.vue";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
    PostExample,
  },
  created() {
    this.$root.$on("scrollToEndpoint", (ref) => {
      var element = this.$refs[ref];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    });
  },
  computed: {
    postTitle() {
      return this.$t("worksheets.postTitle");
    },
    endpointName() {
      return this.$t("worksheets.endpointTitle");
    },
    postAttributes() {
      return this.$t("worksheets.postAttributes_workSheetDTO");
    },
    postAttributes_1() {
      return this.$t("worksheets.postAttributes_2_address");
    },
    postDesc() {
      return this.$t("worksheets.postDesc");
    },
    postExample() {
      return this.$t("worksheets.postExample");
    },
    postResponse() {
      return this.$t("worksheets.postResponse");
    },
    filteringTitle() {
      return this.$t("worksheets.filteringTitle");
    },
    filteringText() {
      return this.$t("worksheets.filteringText");
    },
    responseTitle() {
      return this.$t("worksheets.getTitle");
    },
    responseCodeTitle() {
      return this.$t("worksheets.responseCodeTitle");
    },
    endpointDesc() {
      return this.$t("worksheets.endpointDesc");
    },
    endpoints() {
      return this.$t("worksheets.controllerEndpoints");
    },
    response() {
      return this.$t("worksheets.getResponse");
    },
    attributes() {
      return this.$t("worksheets.getAttributes");
    },
  },
};
</script>
<style scoped>
</style>