import axios from 'axios';

const state = {
    authtoken: null,
};

const getters = {
    isAuthenticated: () => localStorage.getItem('EK-Token3') == null ? false : true,
    Token: state => state.authtoken,
    StatePosts: state => state.posts,
    StateUser: state => state.user,
};

const actions = {
    async LogIn({ commit }, user) {
        try {
            let response = await axios.post(process.env.VUE_APP_BASEURL_AUTH + '/auth/login', user);
            let authtoken = response.data;
            commit('setUser', authtoken);
            return authtoken;
        } catch {
            return { error: "Email/Password combination was incorrect. Please try again." };
        }

    },
    async LogOut({ commit }) {
        let user = null;
        commit('logout', user)
    }
};

const mutations = {
    setUser(state, authtoken) {
        state.authtoken = authtoken
        localStorage.setItem('EK-Token3', authtoken);
    },
    LogOut(state) {
        state.user = null
        state.authtoken = null
        localStorage.removeItem('EK-Token3');
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};