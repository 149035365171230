<template>
  <v-card class="pb-4" elevation="0">
    <v-card-text style="background: #3c4257">
      <h4 style="color: #9ea7b4">{{ cardTitle }}</h4>
    </v-card-text>
    <div style="background-color: #4f566b">
      <v-card-text>
        <code v-html="cardCode" style="font-size: 1rem; color: #bbc0c7">{{
          cardCode
        }}</code>
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
export default {
  props: ["cardTitle", "cardCode"],
};
</script>