<template>
  <v-card color="#f7fafc" class="pb-4">
    <v-card-text style="background: #e3e8ee">
      <h4 style="color: #8992a2">{{ cardTitle }}</h4>
    </v-card-text>
    <div>
      <v-card-text>
        <div v-bind:key="index" v-for="(endpoint, index) in endpoints">
          <button @click="emitScrollToEndpoint(endpoint.ref)">
            <code
              v-if="endpoint.verb == 'GET '"
              style="color: #1b739f; background-color: inherit; font-size: 1rem"
              >{{ endpoint.verb }}</code
            >
            <code
              v-else-if="endpoint.verb == 'PUT '"
              style="color: #fba334; background-color: inherit; font-size: 1rem"
              >{{ endpoint.verb }}</code
            >
            <code
              v-else-if="endpoint.verb == 'POST'"
              style="color: #21765d; background-color: inherit; font-size: 1rem"
              >{{ endpoint.verb }}</code
            >
            <code
              v-else-if="endpoint.verb == 'DELETE'"
              style="color: #e56f4a; background-color: inherit; font-size: 1rem"
              >{{ endpoint.verb }}</code
            >
            <code style="background-color: inherit; font-size: 1rem">{{
              endpoint.path
            }}</code>
            <p></p>
          </button>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
export default {
  props: ["cardTitle", "endpoints"],
  methods: {
    emitScrollToEndpoint(refName) {
      this.$root.$emit("scrollToEndpoint", refName);
    },
  },
};
</script>