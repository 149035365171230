<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "Offers";
const ekURL = "/Projects/Offers";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
        "Offers endpointet dækker over tilbud i E-Komplet. På endpointet kan du udtrække tilbud, relateret PDF'er, oprette, slette og opdatere kommentarer. Derudover kan du også opdatere tilstanden på dit tilbud.",
        "Nedenstående kan du læse om hvilke værdier man kan hente ud, samt hvordan man opretter, sletter og opdaterer kommentarer."
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/pdf`,
        },
        {
          verb: "PUT ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/state`,
        },
        {
          verb: "POST",
          path: `/api/v3.0/${endPointName.toLowerCase()}/comment`,
        },
        {
          verb: "DELETE",
          path: `/api/v3.0/${endPointName.toLowerCase()}/comment`,
        },
        {
          verb: "PUT ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/comment`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/:id`,
        },
      ],
      response: [
        {
          ID: 2,
          Status: "Accepteret",
          OfferNumber: "1000",
          Description:
            "tilbud på nyt hus.",
          ResponsibleUserID: "084b9b51-b4f4-4098-991b-8f9e47fa9702",
          ResponsibleUserName: "admin",
          CustomerID: 8,
          CustomerName: "Mads Hjortlund",
          ContactName: null,
          AssociatedPerson: null,
          ContactPhone: null,
          ContactEmail: null,
          DueDate: "2014-02-19T23:00:00Z",
          ValidUntilDate: "2014-03-19T23:00:00Z",
          StartDate: "2014-02-26T23:00:00Z",
          EndDate: null,
          CalculationID: 1,
          PriceAgreementId: null,
          SalesPrice: 2505.0,
          Coverage: -3090.7065,
          Confidence: 100.0,
          TurnOver: 2505.0,
          DateCreated: "2014-02-13T10:16:01Z",
          IsDebtorLocked: false,
          CreatedBy: "admin",
          ProjectID: 28,
          ProjectReference: "9002",
          ProjectIsClosed: false,
          Actions: null,
          Note: null,
          DeleteOffer: false,
          ManageFiles: true,
          AcceptOffer: false,
          CreateProject: false,
          ProjectDetails: true,
          RejectOffer: false,
          DownloadOffer: false,
          CloneOffer: false,
        },
      ],
      attributes: [
        {
          name: "ID",
          desc: "Unique identifier of the offer.",
        },
        {
          name: "responsibleUserID",
          desc: "The user that has posted the offer.",
        },
        {
          name: "CustomreID",
          desc: "",
        },
        {
          name: "CalculationID",
          desc: "",
        },
        {
          name: "PriceAgreementId",
          desc: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>