<template>
  <v-container>
    <v-row color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="authTextTitle"
          :textLinesArray="introductionText"
        ></TextArea>
        <TextArea
          :textTitle="requirementsTextTitle"
          :textLinesArray="requirementsText"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div>
          <p>{{ $t("authentication.code-card.header") }}</p>
          <CurlCard :cardTitle="codeTitle" :cardCodeHtml="code" />
          <notification notification-text="Copied Code!" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import TextArea from "../components/content/TextArea.vue";
import CurlCard from "../components/content/CurlCard.vue";
import Notification from "../components/notification/Notification.vue";

export default {
  components: {
    CurlCard,
    TextArea,
    Notification,
  },
  computed: {
    code() {
      return this.$t("authentication.code-card.code");
    },
    codeTitle() {
      return this.$t("authentication.code-card.title");
    },
    authTextTitle() {
      return this.$t("authentication.title");
    },
    introductionText() {
      return this.$t("authentication.text");
    },
    requirementsTextTitle() {
      return this.$t("termsOfUse.title");
    },
    requirementsText() {
      return this.$t("termsOfUse.text");
    }
  },
};
</script>
<style scoped>
</style>