<template>
  <v-icon dark right color="#9ea7b4" @click="copyText">
    mdi-content-copy
  </v-icon>
</template>
<script>
export default {
  props: ["textToCopy"],
  methods: {
    async copyText() {
      this.$root.$emit("notify");
      navigator.clipboard.writeText(this.textToCopy);
    },
  },
};
</script>
<style>
</style>