<template>
  <v-container fluid fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar color="#484444" dark flat>
            <v-toolbar-title>Login to continue</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <p>Sign in with your username and password:</p>
            <v-form>
              <v-text-field
                label="Login"
                name="login"
                type="text"
                v-model="form.username"
                outlined
                color="#484444"
              ></v-text-field>
              <v-text-field
                id="password"
                outlined
                v-model="form.password"
                label="Password"
                name="password"
                type="password"
                color="#484444"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              type="submit"
              class="mb-3 mr-3"
              @click="submit"
              dark
              color="#484444"
              >Login</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showError: false,
    };
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      const loginInfo = {
        Username: this.form.username,
        Password: this.form.password,
      };
      let user = await this.$store.dispatch("LogIn", loginInfo);
      if (user.error) {
        alert(user.error);
      } else {
        this.$router.push("/customers");
      }
    },
  },
};
</script>
