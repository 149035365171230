<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "Users";
const ekURL = "/users";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
        "Brugere endpointet dækker over brugerne i E-Komplet. På endpointet kan man hente brugerinformation i E-Komplet systemet.",
        "Nedenstående kan man læse om hvilke værdier der kan hentes ud igennem API'et."
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/:id`,
        },
      ],
      response: [
        {
          "Select": true,
          "Actions": null,
          "UserName": "john1",
          "FirstName": "John",
          "SurName": "Miller Johnson",
          "Employee": "John Miller Johnson",
          "Phone": "",
          "Email": "john1@e-komplet.dk",
          "Status": "Aktiveret",
          "RoleGroupString": "Administrator, Beholdningsstyring",
          "ID": "f1aaa111-2151-4207-aedf-aed8ce1f1111",
          "IsApproved": true,
          "IsLockedOut": false,
          "EmployeeID": 111,
          "IsSubscribedToInfoMail": true,
          "IsSubscribedToNewsletter": false,
          "IsReadyToBillEditableInApp": false,
          "IsReadyToBillVisibleInApp": false,
          "ScalePointUsername": null,
          "UserClaims": null,
          "RoleNames": [
            "Fakturagodkender",
          ],
        "Roles": [
          "11111aa1-fcf9-403e-903b-05d5f7f1111a",
      ],
          "editUser": true,
          "deleteUser": true,
          "lockUser": true,
          "unlockUser": false,
          "resendActivationEmail": true
    },
      ],
      attributes: [
        {
          "name": "Select",
          "desc": ""
        },
        {
          "name": "Actions",
          "desc": ""
        },
        {
          "name": "UserName",
          "desc": "The username associated with the user's account"
        },
        {
          "name": "FirstName",
          "desc": "The user's first name"
        },
        {
          "name": "SurName",
          "desc": "The user's surname"
        },
        {
          "name": "Employee",
          "desc": ""
        },
        {
          "name": "Phone",
          "desc": "The user's phone number"
        },
        {
          "name": "Email",
          "desc": "The user's email address"
        },
        {
          "name": "Status",
          "desc": "The current status of the user's account"
        },
        {
          "name": "RoleGroupString",
          "desc": "String containing comma-separated roles of the user"
        },
        {
          "name": "ID",
          "desc": "The unique identifier of the user"
        },
        {
          "name": "IsApproved",
          "desc": ""
        },
        {
          "name": "IsLockedOut",
          "desc": "A boolean indicating whether the user's account is locked"
        },
        {
          "name": "EmployeeID",
          "desc": "The unique identifier for the employee"
        },
        {
          "name": "IsSubscribedToInfoMail",
          "desc": "A boolean indicating if the user is subscribed to information emails"
        },
        {
          "name": "IsSubscribedToNewsletter",
          "desc": "A boolean indicating if the user is subscribed to newsletters"
        },
        {
          "name": "IsReadyToBillEditableInApp",
          "desc": ""
        },
        {
          "name": "IsReadyToBillVisibleInApp",
          "desc": ""
        },
        {
          "name": "ScalePointUsername",
          "desc": ""
        },
        {
          "name": "UserClaims",
          "desc": ""
        },
        {
          "name": "RoleNames",
          "desc": ""
        },
        {
          "name": "Roles",
          "desc": ""
        },
        {
          "name": "editUser",
          "desc": ""
        },
        {
          "name": "deleteUser",
          "desc": ""
        },
        {
          "name": "lockUser",
          "desc": ""
        },
        {
          "name": "unlockUser",
          "desc": ""
        },
        {
          "name": "resendActivationEmail",
          "desc": ""
        }
      ],
    };
  },
};
</script>
<style scoped>
</style>
