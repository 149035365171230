import Vue from 'vue'
import store from "../store";
import Introduction from '../views/Introduction.vue'
import Authentication from '../views/Authentication.vue'
import Filtering from '../views/Filtering.vue'
import Debtor from '../views/Endpoints/Debtor.vue'
import PurchaseInvoices from '../views/Endpoints/PurchaseInvoices.vue'
import PurchaseInvoiceLines from '../views/Endpoints/PurchaseInvoiceLines.vue'
import FinancialPost from '../views/Endpoints/FinancialPosts.vue'
import FinancialAccounts from '../views/Endpoints/FinancialAccounts.vue'
import Worksheets from '../views/Endpoints/Worksheets.vue'
import Users from '../views/Endpoints/Users.vue'
import Creditors from '../views/Endpoints/Creditors.vue'
import PriceGroups from '../views/Endpoints/PriceGroups.vue'
import FitterHours from '../views/Endpoints/FitterHours.vue'
import FitterCategories from '../views/Endpoints/FitterCategories.vue'
import ProjectGroups from '../views/Endpoints/ProjectGroups.vue'
import ClosedProjects from '../views/Endpoints/ClosedProjects.vue'
import InvoiceLines from '../views/Endpoints/InvoiceLines.vue'
import Fitters from '../views/Endpoints/Fitters.vue'
import SalaryPeriods from '../views/Endpoints/SalaryPeriods.vue'
import CalendarEvents from '../views/Endpoints/CalendarEvents.vue'
import FinancialPostParentAccounts from '../views/Endpoints/FinancialPostParentAccounts.vue'
import Offers from '../views/Endpoints/Offers.vue'
import DepartmentDimensions from '../views/Endpoints/DepartmentDimensions.vue'
import Budgets from '../views/Endpoints/Budgets.vue'
import CarrierDimensions from '../views/Endpoints/CarrierDimensions.vue'
import ProfitGroups from '../views/Endpoints/ProfitGroups.vue'
import PurposeDimensions from '../views/Endpoints/PurposeDimensions.vue'
import Invoices from '../views/Endpoints/Invoices.vue'
import Response from '../views/Response.vue'
import Project from '../views/Endpoints/Project.vue'
import Versionering from '../views/Versionering.vue'
import Customers from '../views/Admin/Customers.vue'
import SitesForCustomer from '../views/Admin/SitesForCustomer.vue'
import EndpointsForCustomer from '../views/Admin/EndpointsForCustomer.vue'
import Login from '../views/Login.vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/introduction',
  name: 'Introduction',
  component: Introduction,
},
{
  path: '/authentication',
  name: 'Authentication',
  component: Authentication,
},
{
  path: '/debtors',
  name: 'Debtor',
  component: Debtor,
},
{
  path: '/budgets',
  name: 'Budgets',
  component: Budgets,
},
{
  path: '/purchaseinvoices',
  name: 'PurchaseInvoices',
  component: PurchaseInvoices,
},
{
  path: '/purchaseinvoicelines',
  name: 'PurchaseInvoiceLines',
  component: PurchaseInvoiceLines,
},
{
  path: '/invoices',
  name: 'Invoices',
  component: Invoices,
},
{
  path: '/pricegroups',
  name: 'PriceGroups',
  component: PriceGroups,
},
{
  path: '/profitgroups',
  name: 'ProfitGroups',
  component: ProfitGroups,
},
{
  path: '/financialpost',
  name: 'FinancialPost',
  component: FinancialPost,
},
{
  path: '/projects',
  name: 'Project',
  component: Project,
},
{
  path: '/worksheets',
  name: 'Worksheets',
  component: Worksheets,
},
{
  path: '/users',
  name: 'Users',
  component: Users,
},
{
  path: '/calendarevents',
  name: 'CalendarEvents',
  component: CalendarEvents,
},
{
  path: '/financialaccounts',
  name: 'FinancialAccounts',
  component: FinancialAccounts,
},
{
  path: '/creditors',
  name: 'Creditors',
  component: Creditors,
},
{
  path: '/fitterhours',
  name: 'FitterHours',
  component: FitterHours,
},
{
  path: '/fittercategories',
  name: 'FitterCategories',
  component: FitterCategories,
},
{
  path: '/projectgroups',
  name: 'ProjectGroups',
  component: ProjectGroups,
},
{
  path: '/closedprojects',
  name: 'ClosedProjects',
  component: ClosedProjects,
},
{
  path: '/invoicelines',
  name: 'InvoiceLines',
  component: InvoiceLines,
},
{
  path: '/fitters',
  name: 'Fitters',
  component: Fitters,
},
{
  path: '/salaryperiods',
  name: 'SalaryPeriods',
  component: SalaryPeriods,
},
{
  path: '/financialpostparentaccounts',
  name: 'FinancialPostParentAccounts',
  component: FinancialPostParentAccounts,
},
{
  path: '/offers',
  name: 'Offers',
  component: Offers,
},
{
  path: '/departmentdimensions',
  name: 'DepartmentDimensions',
  component: DepartmentDimensions,
},
{
  path: '/carrierdimensions',
  name: 'CarrierDimensions',
  component: CarrierDimensions,
},
{
  path: '/purposedimensions',
  name: 'PurposeDimensions',
  component: PurposeDimensions,
},
{
  path: '/filtering',
  name: 'Filtering',
  component: Filtering,
},
{
  path: '/versioning',
  name: 'Versionering',
  component: Versionering,
},
{
  path: '/response',
  name: 'Response',
  component: Response,
},
{
  path: '/customers',
  name: 'Customers',
  meta: { requiresAuth: true },
  component: Customers,
},
{
  path: '/customers/:id/:name',
  component: SitesForCustomer
},
{
  path: '/customers/:id/site/:siteId',
  component: EndpointsForCustomer
},
{
  path: '',
  component: Introduction
},
{
  path: '/login',
  component: Login,
  name: 'Login',
  meta: { guest: true },
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router
