<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="textTitle"
          :textLinesArray="introductionText"
        ></TextArea>
        <TextArea :textTitle="dateTitle" :textLinesArray="dateText"></TextArea>
        <TextArea
          :textTitle="paginationTitle"
          :textLinesArray="paginationText"
        ></TextArea>
        <TextArea
          :textTitle="searchTitle"
          :textLinesArray="searchText"
        ></TextArea>
        <TextArea
          :textTitle="deltaSearchTitle"
          :textLinesArray="deltaSearchText"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div>
          <p>{{ filteringHeader }}</p>
          <CodeCard
            :cardTitle="codeCardFilteringTitle"
            :cardCode="codeCardFilteringCode"
          />
        </div>
        <div>
          <p>{{ searchHeader }}</p>
          <CodeCard
            :cardTitle="codeCardSearchTitle"
            :cardCode="codeCardSearchCode"
          />
        </div>
        <div>
          <p>{{ deltaSearchIDHeader }}</p>
          <CodeCard
            :cardTitle="codeCardDeltaSearchWithIDTitle"
            :cardCode="codeCardDeltaSearchWithIDCode"
          />
        </div>
        <div>
          <p>{{ deltaSearchDateHeader }}</p>
          <CodeCard
            :cardTitle="codeCardDeltaSearchWithDateTitle"
            :cardCode="codeCardDeltaSearchWithDateCode"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CodeCard from "../components/content/CodeCard.vue";
import TextArea from "../components/content/TextArea.vue";

export default {
  components: {
    CodeCard,
    TextArea,
  },
  computed: {
    codeCardSearchTitle() {
      return this.$t("filtering.code-card-search.title");
    },
    codeCardSearchCode() {
      return this.$t("filtering.code-card-search.code");
    },
    codeCardFilteringTitle() {
      return this.$t("filtering.code-card-filtering.title");
    },
    codeCardFilteringCode() {
      return this.$t("filtering.code-card-search.code");
    },
    searchHeader() {
      return this.$t("filtering.code-card-search.header");
    },
    filteringHeader() {
      return this.$t("filtering.code-card-filtering.header");
    },
    paginationTitle() {
      return this.$t("filtering.paginationTitle");
    },
    paginationText() {
      return this.$t("filtering.paginationText");
    },
    searchTitle() {
      return this.$t("filtering.searchTitle");
    },
    searchText() {
      return this.$t("filtering.searchText");
    },
    dateTitle() {
      return this.$t("filtering.dateTitle");
    },
    dateText() {
      return this.$t("filtering.dateText");
    },
    textTitle() {
      return this.$t("filtering.title");
    },
    introductionText() {
      return this.$t("filtering.text");
    },    
    deltaSearchTitle() {
      return this.$t("filtering.deltaSearchTitle");
    },
    deltaSearchText() {
      return this.$t("filtering.deltaSearchText");
    },
    codeCardDeltaSearchWithIDTitle() {
      return this.$t("filtering.code-card-deltaSearch-id.title");
    },
    codeCardDeltaSearchWithIDCode() {
      return this.$t("filtering.code-card-deltaSearch-id.code");
    },
    codeCardDeltaSearchWithDateTitle() {
      return this.$t("filtering.code-card-deltaSearch-date.title");
    },
    codeCardDeltaSearchWithDateCode() {
      return this.$t("filtering.code-card-deltaSearch-date.code");
    },
    deltaSearchIDHeader() {
      return this.$t("filtering.code-card-deltaSearch-id.header");
    },
    deltaSearchDateHeader() {
      return this.$t("filtering.code-card-deltaSearch-date.header");
    }
  },
};
</script>
<style scoped>
</style>