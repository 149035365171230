<template>
  <div>
    <div class="container">
      <TextArea
        class="child"
        :textTitle="endpointName"
        :textLinesArray="endpointDesc"
      ></TextArea>
      <EndpointCard
        class="child"
        cardTitle="ENDPOINTS"
        :endpoints="endpoints"
      />
      <TextArea
        class="child"
        :textTitle="filteringTitle"
        :textLinesArray="filteringText"
      ></TextArea>
    </div>
    <div :ref="endpoints[0].ref" class="container">
      <Attribute
        class="child"
        :attributes="attributes"
        :endpoint="responseTitle"
      />
      <ResponseCard
        class="child"
        :response="response"
        :cardTitle="responseCodeTitle"
      />
    </div>
    <div :ref="endpoints[3].ref" class="container">
      <div class="child">
        <TextArea :textTitle="postTitle" :textLinesArray="postDesc"></TextArea>
        <div>
          <Attribute
            :attributes="postAttributes"
            endpoint="ExternalProjectDTO"
          />
          <Attribute
            :attributes="postAttributes_1"
            endpoint="AssociationAddressDTO"
          />
          <Attribute
            :attributes="postAttributes_2"
            endpoint="ExternalProjectWorksheetDTO"
          />
          <Attribute
            :attributes="postAttributes_3"
            endpoint="ProjectCalenderEventAddRequestDTO"
          />
        </div>
      </div>
      <div class="child">
        <PostExample
          :postExample="postExample"
          cardTitle="POST: /api/v3.0/projects"
        />
        <ResponseCard
          class="mt-5"
          :response="postResponse"
          cardTitle="RESPONSE."
        />
      </div>
    </div>
    <div :ref="endpoints[4].ref" class="container">
      <div class="child">
        <TextArea
          :textTitle="postProjectsDocumentationTitle"
          :textLinesArray="postProjectsDocumentationDescription"
        ></TextArea>
        <div>
          <Attribute
            :attributes="postProjectsDocumentationDTO"
            endpoint="ExternalFileDTO"
          />
        </div>
      </div>
      <div class="child">
        <PostExample
          :postExample="postProjectsDocumentationCardCodeExample"
          :cardTitle="postProjectsDocumentationCardTitle"
        />
        <ResponseCard
          class="mt-5"
          :response="postProjectDocumentationResponseExample"
          cardTitle="RESPONSE."
        />
      </div>
    </div>
    <div :ref="endpoints[5].ref" class="container">
      <div class="child">
        <TextArea
          :textTitle="postProjectsBudgetsTitle"
          :textLinesArray="postProjectsBudgetsDescription"
        ></TextArea>
        <div>
          <Attribute
            :attributes="postProjectsBudgetsDTO"
            endpoint="ExternalBudgetsDTO"
          />
          <Attribute
            :attributes="BudgetCreditorDTO"
            endpoint="BudgetCreditorDTO"
          />
          <Attribute :attributes="BudgetMiscDTO" endpoint="BudgetMiscDTO" />
          <Attribute
            :attributes="BudgetCreditorDTO"
            endpoint="BudgetCreditorDTO"
          />
          <Attribute :attributes="BudgetCostDTO" endpoint="BudgetCostDTO" />
        </div>
      </div>
      <div class="child">
        <PostExample
          :postExample="postProjectsBudgetsCardCodeExample"
          :cardTitle="postProjectsBudgetsCardTitle"
        />
        <ResponseCard
          class="mt-5"
          :response="postProjectBudgetsResponseExample"
          cardTitle="RESPONSE."
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.postCard {
  max-height: 600px;
}

.container {
  display: flex;
  flex-flow: row wrap;
  padding-left: 10rem;
  padding-right: 10rem;
}

.child {
  min-width: 40%;
  max-width: 45%;
  flex-grow: 1;
  flex-shrink: 1;
}

@media screen and (max-width: 500px) {
  .container {
    flex-flow: column wrap;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 500px) {
  .child {
    max-width: 380px;
    min-width: 300px;
  }
}
</style>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";
import PostExample from "../../components/request/PostExample.vue";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
    PostExample,
  },
  created() {
    this.$root.$on("scrollToEndpoint", (ref) => {
      var element = this.$refs[ref];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    });
  },
  computed: {
    postTitle() {
      return this.$t("projects.postTitle");
    },
    endpointName() {
      return this.$t("projects.endpointTitle");
    },
    postAttributes() {
      return this.$t("projects.postAttributes_1_projectDto");
    },
    postAttributes_1() {
      return this.$t("projects.postAttributes_2_address");
    },
    postAttributes_2() {
      return this.$t("projects.postAttributes_worksheetsDTO");
    },
    postAttributes_3() {
      return this.$t("projects.postAttributes_3_calendarEvent");
    },
    postDesc() {
      return this.$t("projects.postDesc");
    },
    postExample() {
      return this.$t("projects.postExample");
    },
    postResponse() {
      return this.$t("projects.postResponse");
    },
    filteringTitle() {
      return this.$t("projects.filteringTitle");
    },
    filteringText() {
      return this.$t("projects.filteringText");
    },
    responseTitle() {
      return this.$t("projects.getTitle");
    },
    responseCodeTitle() {
      return this.$t("projects.responseCodeTitle");
    },
    endpointDesc() {
      return this.$t("projects.endpointDesc");
    },
    endpoints() {
      return this.$t("projects.controllerEndpoints");
    },
    response() {
      return this.$t("projects.getResponse");
    },
    attributes() {
      return this.$t("projects.getAttributes");
    },
    postProjectsDocumentationTitle() {
      return this.$t("projects.postProjectsDocumentationTitle");
    },
    postProjectsDocumentationDescription() {
      return this.$t("projects.postProjectsDocumentationDescription");
    },
    postProjectsDocumentationCardTitle() {
      return this.$t("projects.postProjectsDocumentationCardTitle");
    },
    postProjectsDocumentationCardCodeExample() {
      return this.$t("projects.postProjectsDocumentationCardCodeExample");
    },
    postProjectsDocumentationDTO() {
      return this.$t("projects.postProjectsDocumentationDTO");
    },
    postProjectDocumentationResponseExample() {
      return this.$t("projects.postProjectDocumentationResponseExample");
    },
    postProjectsBudgetsTitle() {
      return this.$t("projects.postProjectsBudgetsTitle");
    },
    postProjectsBudgetsDescription() {
      return this.$t("projects.postProjectsBudgetsDescription");
    },
    postProjectsBudgetsCardTitle() {
      return this.$t("projects.postProjectsBudgetsCardTitle");
    },
    postProjectsBudgetsCardCodeExample() {
      return this.$t("projects.postProjectsBudgetsCardCodeExample");
    },
    postProjectsBudgetsDTO() {
      return this.$t("projects.postProjectsBudgetsDTO");
    },
    BudgetCreditorDTO() {
      return this.$t("projects.BudgetCreditorDTO");
    },
    BudgetsDTO() {
      return this.$t("projects.BudgetsDTO");
    },
    BudgetCostDTO() {
      return this.$t("projects.BudgetCostDTO");
    },
    BudgetMiscDTO() {
      return this.$t("projects.BudgetMiscDTO");
    },
    BudgetSalesRequestDTO() {
      return this.$t("projects.BudgetSalesRequestDTO");
    },
    postProjectBudgetsResponseExample() {
      return this.$t("projects.postProjectsBudgetsResponseExample");
    },
  },
};
</script>
<style scoped>
</style>