<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "FinancialAccounts";
const ekURL = "/Accountancy/FinancialAccounts";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
        "FinancialAccounts dækker over finanskontoer i E-Komplet. På endpointet kan du hente finanskontoer som ligger i E-Komplet.",
        "Nedenstående kan man læse om hvilke værdier man kan trække ud igennem API'et."
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
      ],
      response: [
        {
          ID: "00000000-0000-0000-0000-000000000000",
          Reference: null,
          Description: null,
          FinancialAccountID: null,
          GroupID: 2,
          Name: "Balance",
          Number: 1000,
          EndNumber: 10000,
          NumberText: "1000-10000",
          IsCredit: false,
          IsBalance: true,
          VatType: null,
          VatPercent: null,
          IsPostable: null,
          CarrierEnabled: null,
          CarrierRequired: null,
          PurposeEnabled: null,
          PurposeRequired: null,
          DepartmentEnabled: null,
          DepartmentRequired: null,
          IsActive: true,
          IsDeletable: true,
          GroupType: "Balance",
          Carrier: "",
          Purpose: "",
          Department: "",
          Balance: null,
          Actions: null,
          EditLink: true,
          Archive: true,
          UnArchive: false,
          PostsLink: false,
          BudgetLink: false,
          DeleteLink: true,
        },
      ],
      attributes: [
        {
          name: "ID",
          desc: "Unique identifier of the financial account.",
        },
        {
          name: "FinancialAccountID",
          desc: "Unique identifier of the financial acconut.",
        },
        {
          name: "GroupID",
          desc: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>