<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="1200px">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item v-bind="attrs" fab v-on="on" class="ma-3" center link>
          <v-list-item-icon>
            <v-icon>mdi-account-multiple-outline </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Tilføj Kunde</v-list-item-title>
        </v-list-item>
      </template>
      <v-card>
        <v-form @submit="onSubmit" ref="form" v-model="isFormValid">
        <v-card-title>
          <span class="text-h5">Tilføj Kunde</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="customerName"
                  label="Kundenavn*"
                  required
                  :counter="50"
                  :rules="textFieldRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="contactName"
                  label="Kontaktpersonens navn*"
                  required
                  counter="50"
                  :rules="textFieldRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                    v-model="contactEmail"
                    label="Kontaktpersonens e-mail*"
                    required
                    type="email"
                    counter="50"
                    :rules="emailRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                    v-model="contactPhone"
                    label="Kontaktpersonens telefonnummer*"
                    required
                    type="tel"
                    counter="50"
                    :rules="textFieldRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="createdBy"
                  label="Oprettet af*"
                  required
                  counter="50"
                  :rules="textFieldRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="validUntil"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="validUntil"
                      label="Gældende indtil"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="validUntil" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(validUntil)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="selectedSiteIDs"
                  :items="siteSelecter"
                  label="Select"
                  hint="Vælg sites"
                  persistent-hint
                  multiple
                  clearable
                  chips
                  deletable-chips
                  small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-container class="px-0" fluid>
                  <v-switch
                    v-model="accessAll"
                    :label="`Adgang til alle endpoints: ${accessAll.toString()}`"
                  ></v-switch>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
          <small>* indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn
            type="submit"
            color="blue darken-1"
            text
            :disabled="isSubmitDisabled || !isFormValid"
          >
            Save
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  mounted() {
    axios
      .get(process.env.VUE_APP_BASEURL_EXTERNALAPI + "/Admin/sites", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("EK-Token3"),
        },
      })
      .then((response) => {
        this.sitesData = response.data.data;
        this.siteSelecter = this.sitesData.map((site) => ({
          value: {
            ExternalCustomerID: 0,
            ActiveSiteID: site.recordId,
          },
          text: site.name,
        }));
      });
  },
  data: () => ({
    validUntil: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    customerName: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    createdBy: "",
    accessAll: false,
    dialog: false,
    isSubmitDisabled: false,
    isFormValid: false,
    selectedSiteIDs: [],
    sitesData: [],
    siteSelecter: [],
    textFieldRules: [
      value => {
        if (value) return true

        return 'Dette felt er påkrævet'
      },
    ],
    emailRules: [
      value => {
        if (value) return true

        return 'E-mail er påkrævet'
      },
      value => {
        if (/.+@.+\..+/.test(value)) return true

        return 'E-mail skal være gyldig'
      },
    ],
  }),
  methods: {
    onSubmit() {
      event.preventDefault();
      if (!this.$refs.form.validate()) return;
      this.isSubmitDisabled = true;

      let validUntilString =
        this.validUntil.substring(0, 4) +
        this.validUntil.substring(5, 7) +
        this.validUntil.substring(8, 10);

      const customer = [
        {
          CustomerName: this.customerName,
          ContactName: this.contactName,
          ContactEmail: this.contactEmail,
          ContactPhone: this.contactPhone,
          ValidUntil: validUntilString,
          CreatedByUser: this.createdBy,
          AccessAll: this.accessAll,
        },
      ];

      axios
        .post(
          process.env.VUE_APP_BASEURL_EXTERNALAPI +
            "/Admin/CreateExternalCustomers",
          customer,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("EK-Token3"),
            },
          }
        )
        .then((response) => {
          this.selectedSiteIDs.forEach((dto) => {
            dto.ExternalCustomerID = response.data.successObjects[0].customerID;
          });
        })
        .then(() => {
          if (this.selectedSiteIDs.length > 0) {
            axios.post(
              process.env.VUE_APP_BASEURL_EXTERNALAPI +
                "/Admin/AddSiteAccessToExternalCustomer",
              this.selectedSiteIDs,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("EK-Token3"),
                },
              }
            );
          }
        })
        .then(() => {
          this.$root.$emit("customerAdded");
          this.dialog = false;
          this.clearForm();
        })
          .catch((error) => {
            const defaultErrorMessage = "Der er sket en fejl. Prøv igen senere.";

            const extractErrorMessages = (errors) => Object.values(errors).flat().join('\n');

            const getMessage = () => {
              if (!error.response || !error.response.status) return defaultErrorMessage;

              switch (error.response.status) {
                case 400:
                  return error.response.data && error.response.data.errors
                      ? extractErrorMessages(error.response.data.errors)
                      : defaultErrorMessage;
                case 401:
                  return "Du er ikke logget ind. Log ind og prøv igen.";
                default:
                  return defaultErrorMessage;
              }
            };

            alert(getMessage());
          })
        .finally(() => {
          this.isSubmitDisabled = false;
        });
    },
    clearForm() {
      this.customerName = "";
      this.contactName = "";
      this.contactEmail = "";
      this.contactPhone = "";
      this.createdBy = "";
      this.accessAll = false;
      this.selectedSiteIDs = [];
    }
  },
};
</script>