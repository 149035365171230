<template>
  <v-card class="mx-auto">
    <v-navigation-drawer
      mobile-breakpoint="600"
      v-model="drawer"
      app
      width="310"
    >
      <v-list nav dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item>
              <v-img
                lazy-src="@/assets/ekomplet_480.png"
                max-height="150"
                max-width="250"
                src="@/assets/ekomplet_480.png"
              ></v-img>
            </v-list-item>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item link to="/introduction">
          <v-list-item-title>Introduction</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/authentication">
          <v-list-item-title>Authentication</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/versioning">
          <v-list-item-title>Versioning</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/filtering">
          <v-list-item-title>Filtering</v-list-item-title>
        </v-list-item>
        <v-list-item link to="/response">
          <v-list-item-title>Response</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isLoggedIn" link to="/customers">
          <v-list-item-title>Admin Panel</v-list-item-title>
        </v-list-item>
        <v-list-group :value="false" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Core Resources</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="([title, url], i) in sortedArray"
            :key="i"
            link
            :to="url"
          >
            <v-list-item-title v-text="title"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">API VERSION: 3.0</div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>
<style scoped>
</style>
<script>
export default {
  created() {
    this.$root.$on("toggle-side-bar", () => {
      this.drawer = !this.drawer;
    });
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
    sortedArray: function () {
      return this.coreResources.slice(0).sort();
    },
  },
  data() {
    return {
      drawer: true,
      coreResources: [
        ["PriceGroups", "/pricegroups"],
        ["ProfitGroups", "/profitgroups"],
        ["Debtors", "/debtors"],
        ["Projects", "/projects"],
        ["Invoices", "/invoices"],
        ["PurchaseInvoices", "/purchaseinvoices"],
        ["FinancialPosts", "/financialpost"],
        ["Worksheets", "/worksheets"],
        ["CalendarEvents", "/calendarevents"],
        ["FinancialAccounts", "/financialaccounts"],
        ["Creditors", "/creditors"],
        ["FitterHours", "/fitterhours"],
        ["FitterCategories", "/fittercategories"],
        ["ProjectGroups", "/projectgroups"],
        ["ClosedProjects", "/closedprojects"],
        ["InvoiceLines", "/invoicelines"],
        ["PurchaseInvoiceLines", "/purchaseinvoicelines"],
        ["Fitters", "/fitters"],
        ["SalaryPeriods", "/salaryperiods"],
        ["Budgets", "/budgets"],
        ["FinancialPostParentAccounts", "/financialpostparentaccounts"],
        ["Offers", "/offers"],
        ["DepartmentDimensions", "/departmentdimensions"],
        ["CarrierDimensions", "/carrierdimensions"],
        ["PurposeDimensions", "/purposedimensions"],
        ["Users", "/users"]
      ],
    };
  },
};
</script>
