<template>
  <v-card color="#f7fafc" class="pb-4">
    <v-card-text style="background: #e3e8ee">
      <h4 style="color: #8992a2">{{ cardTitle }}</h4>
    </v-card-text>
    <pre class="pl-6">
<code style="background-color: inherit">
{{jsonResponse}}
</code>
</pre>
  </v-card>
</template>
<script>
export default {
  props: ["cardTitle", "response"],
  data() {
    return {
      jsonResponse: this.response,
    };
  },
};
</script>
<style scoped>
pre {
  padding-left: 10px;
}
code {
  white-space: pre-wrap;
}
</style>