<template>
  <v-card class="pb-4" elevation="0">
    <v-card-text style="background: #3c4257">
      <h4 style="color: #9ea7b4">
        {{ cardTitle }}
        <v-icon
          style="float: right"
          dark
          right
          color="#9ea7b4"
          @click="copyText"
        >
          mdi-content-copy
        </v-icon>
      </h4>
    </v-card-text>
    <div style="background-color: #4f566b">
      <v-card-text>
        <div v-html="cardCodeHtml" style="font-size: 1.2rem; color: #bbc0c7">
          {{ cardCodeHtml }}
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>
<script>
export default {
  props: ["cardTitle", "cardCodeHtml"],
  data() {
    return {
      alert: false,
      curlCode:
        'Invoke-RestMethod -Uri "https://externalaccessapitest.e-komplet.dk/api/v3.0/debtors?page=1&pageSize=10" -Method \'GET\' -Headers @{ \n"apikey" = "6CACBCDB-612A-46A3-8D8C-C5B587BB2699" \n"siteName" = "Ekstern"\n}',
    };
  },
  methods: {
    async copyText() {
      this.$root.$emit("notify");
      navigator.clipboard.writeText(this.curlCode);
    },
  },
};
</script>
<style scoped>
</style>