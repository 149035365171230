<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
        <TextArea
          :textTitle="filteringTitle"
          :textLinesArray="filteringText"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="fileAttributes" :endpoint="fileResponseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="fileResponse" :cardTitle="fileResponseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "Invoices";
const ekURL = "/Invoice/AllInvoices";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      filteringTitle: "Filtrering",
      filteringText: [
        "På dette endpoint kan man påføre filtrering med parameterne, <code>fromDate</code> og <code>toDate</code>. Hvis der ikke er påført filter vil der påføres et default filter, hvor <code>fromDate</code> er datoen 2000/01/01 og <code>toDate</code> er datoen 2030/01/01.",
      ],
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
        "Invoices endpointet dækker over faktura i E-Komplet. På endpointet kan man hente faktura som ligger i E-Komplet.",
        "Nedenstående kan man læse om hvilke værdier der kan hentes ud igennem API'et.",
      ],
      response: [
        {
          ID: 31,
          CustomerID: 10,
          Category: "Regningsarbejde",
          ProjectID: 18,
          EdiTransferStatus: 0,
          EdiPostParsingMessageID: null,
          DebtorFinancialAccountID: 19,
          ProjectIsClosed: true,
          InvoiceType: "Faktura",
          IsDraft: false,
          DebtorReference: "35353535",
          DebitorNote: null,
          DebtorName: "Jane Steffensen",
          DebtorCVR: null,
          _vatZone: "Domestic",
          VatZone: "Indland",
          AppendixNumber: 10024,
          RequisitionNumber: null,
          Date: "2013-12-02T23:00:00Z",
          DueDate: "2013-12-10T23:00:00Z",
          ProjectReference: "2010",
          Title: "nyt bl. bat. gæstetoil.",
          Price: 14838.54,
          RemainingAmount: 0.0,
          Status: "Betalt",
          DeliveryStatus: "Sendt til: johnny@e-komplet.dk",
          PriceWOVat: 11870.83,
          VatAmount: 2967.71,
          PaymentMethod: "Email",
          Department: null,
          Carrier: null,
          Purpose: null,
          EdiReceiver: null,
          PaymentTerms: "8 dage netto",
          ProjectResponsible: "system admin",
          IsOverdue: false,
          CreatedByUser: "system admin",
          CreatedDate: "2013-12-02T23:00:00Z",
          LastUpdatedByUser: "system admin",
          LastUpdatedDate: "2013-12-02T23:00:00Z",
          WorkAddress: "Toften 58",
          SubscriptionID: null,
          SubscriptionReference: null,
          CurrencyCode: "DKK",
          CurrencyRate: 100.0,
          ForeignAmount: 14838.54,
          ForeignRemainingAmount: 0.0,
          ForeignPriceWithoutVat: 11870.83,
          ForeignVatAmount: 2967.71,
          ProjectType: "MiniProject",
          ProjectGroup: "Alm. spjæld VVS",
          Actions: null,
          ShowFiles: true,
          DateRangeStartDate: "2013-12-02T23:00:00Z",
          DateRangeEndDate: "2013-12-02T23:00:00Z",
        },
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/:id`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/:id/fitternote`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/pdf/:id`,
        }
      ],
      attributes: [
        {
          name: "CustomerID",
          desc: "Unique identifier of the customer that associates with the invoice.",
        },
        {
          name: "ProjectID",
          desc: "Unique identifier of the project that associates with the invoice.",
        },
        {
          name: "EdiPostParsingMessageID",
          desc: "",
        },
        {
          name: "DebtorFinancialAccountID",
          desc: "Unique identifier of the financial account of the debtor that is associated iwth the invoice.",
        },
      ],
      fileResponseTitle: `Generated PDF report file object`,
      fileResponseCodeTitle: `${endPointName.toLowerCase()}/:id/file - GENERATED PDF REPORT FILE RESPONSE`,
      fileResponse: [
        {
          FileData: "JVBERi0x...",
          SizeInBytes: 123456,
          MimeType: "application/pdf",
          InvoiceId: 123,
        },
      ],
      fileAttributes: [
        {
          name: "FileData",
          desc: "The file data as byte array encoded in base64.",
        },
        {
          name: "SizeInBytes",
          desc: "The size of the file in bytes.",
        },
        {
          name: "MimeType",
          desc: "The mime type of the file.",
        },
        {
          name: "InvoiceId",
          desc: "The invoice id of the invoice that the file is associated with.",
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>
