<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea :textTitle="getTitle" :textLinesArray="getText"></TextArea>
      </v-col>
      <v-col class="right-column">
        <div>
          <h4>{{ $t("introduction.help.header") }}</h4>
          <p></p>
          <p>{{ $t("introduction.help.text") }}</p>
          <CodeCard
            cardTitle="BASE URL"
            cardCode="https://externalaccessapi.e-komplet.dk/"
          />
          <CodeCard
            cardTitle="BASE URL TEST"
            cardCode="https://externalaccesstestapi.e-komplet.dk/"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CodeCard from "../components/content/CodeCard.vue";
import TextArea from "../components/content/TextArea.vue";

export default {
  components: {
    CodeCard,
    TextArea,
  },
  computed: {
    getTitle() {
      return this.$t("introduction.title");
    },
    getText() {
      return this.$t("introduction.text");
    },
  },
};
</script>
<style scoped>
</style>