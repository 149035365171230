<template>
  <v-container>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
        <TextArea
          :textTitle="filteringTitle"
          :textLinesArray="filteringText"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0"> </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";

const endPointName = "PurchaseInvoiceLines";
const ekURL = "/Accountancy/PurchaseInvoiceLines";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
  },
  data() {
    return {
      filteringTitle: "Filtrering",
      filteringText: ["På dette endpoint kan man påføre filtrering med parameterne, <code>fromDate</code> og <code>toDate</code>. Hvis der ikke er påført filter vil der påføres et default filter, hvor <code>fromDate</code> er datoen 2000/01/01 og <code>toDate</code> er datoen 2030/01/01."],
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
        "PurchaseInvoiceLines dækker salgsfakturalinjer i E-Komplet systemet. På endpointet kan du udtrykke salgsfakturalinjer som ligger i E-Komplet systemet.",
        "Nedenstående kan man læse om hvilke værdier man kan trække ud igennem API'et."
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
      ],
      response: [
        {
"ID": 1,
"GetFilename": "Varekøb",
"PurchaseInvoice": "108420",
"PurchaseInvoiceID": 1,
"FinancialAccount": "16",
"FinancialAccountID": 6,
"AppendixNumber": 500001,
"Date": "2014-05-20T22:00:00Z",
"ItemAdded": "2014-05-20T22:00:00Z",
"Creditor": "Sanistål A/S",
"CreditorReference": "96306000",
"CreditorID": 4,
"VatType": "I25",
"VatTypeID": 2,
"Project": "1002",
"ProjectID": 3,
"Amount": 1.0,
"ItemCode": "1",
"ItemName": "Underlag og levering",
"CatalogName": "Sanistål katalogtvv",
"CatalogItemGroupName": "Diverse",
"Unit": "STK",
"UnitID": 6,
"UnitPrice": 848.0,
"ForeignUnitPrice": 848.0,
"Discount": 0.0,
"Price": 848.0,
"ForeignPrice": 848.0,
"CurrencyCode": "DKK",
"CurrencyRate": 100.0,
"DimensionDepartment": null,
"DimensionDepartmentID": null,
"DimensionCarrier": null,
"DimensionCarrierID": null,
"DimensionPurpose": null,
"DimensionPurposeID": null,
"Ordering": null,
"CreatedBy": null,
"CreatedDate": null,
"StatusEnum": 2,
"IsProjectClosed": true,
"LocationName": "",
"DateRangeStartDate": "0001-01-01T00:00:00Z",
"DateRangeEndDate": "0001-01-01T00:00:00Z"
},
      ],
      attributes: [
        {
          name: "ID",
          desc: "Unique identifier of the purchaseinvoiceline.",
        },
        {
          name: "PurchaseInvoiceID",
          desc: "Unique identifier of the purchaseinvoiceline.",
        },
        {
          name: "FinancialAccountID",
          desc: "Unique identifier of the financial account associated with the purchaseinvoiceline.",
        },
        {
          name: "CreditorID",
          desc: "Unique identifier of the credtior associated with the purchaseinvoiceline.",
        },
        {
          name: "VatTypeID",
          desc: "Unique identifier of the type of VAT on the purchaseinvoiceline.",
        },
        {
          name: "ProjectID",
          desc: "Unique identifier of the project associated with the purchaseinvoiceline.",
        },
        {
          name: "UnitID",
          desc: "",
        },
        {
          name: "DimensionDepartmentID",
          desc: "",
        },
        {
          name: "DimensionCarrierID",
          desc: "",
        },
        {
          name: "DimensionPurposeID",
          desc: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>