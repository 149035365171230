<template>
  <div>
    <h1 class="ml-10 pb-10 pt-10">Adgang til Endpoints</h1>
    <v-navigation-drawer app right permanent expand-on-hover width="356px">
      <v-list nav dense>
        <add-endpoint-button />
      </v-list>
    </v-navigation-drawer>
    <v-data-table
      :headers="headers"
      :items="endpointsData"
      v-model="selected"
      item-key="id"
      class="elevation-1 mr-10 ml-10 mt-10"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import AddEndpointButton from "../../components/buttons/AddEndpointButton.vue";
import axios from "axios";

export default {
  components: {
    AddEndpointButton,
  },
  mounted() {
    this.fetchEndpointsInformation();
    this.$root.$on("endpointAccessAdded", () => {
      this.fetchEndpointsInformation();
    });
  },
  data() {
    return {
      customerName: this.$route.params.name,
      selected: [],
      search: "",
      endpointsData: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Endpoint",
          value: "endpointName",
        },
        {
          text: "Kan Oprette (POST)",
          value: "allowPOST",
        },
        {
          text: "Kan Læse (GET)",
          value: "allowGET",
        },
        {
          text: "Kan Opdatere (PUT)",
          value: "allowPUT",
        },
        {
          text: "Kan Slette (DELETE)",
          value: "allowDELETE",
        },
        { text: "Actions", value: "action" },
      ];
    },
  },
  methods: {
    async fetchEndpointsInformation() {
      await axios
        .get(
          process.env.VUE_APP_BASEURL_EXTERNALAPI +
            `/Admin/endpoints/${this.$route.params.id}/${this.$route.params.siteId}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("EK-Token3"),
            },
          }
        )
        .then((response) => {
          this.endpointsData = response.data.data;
        });
    },
    filterOnlyCapsText(value, search) {
      search = search.toLocaleUpperCase();
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
      );
    },
  },
};
</script>
<style scoped>
</style>