<template>
  <v-container>
    <v-row ref="GET " no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          :textTitle="endPointName"
          :textLinesArray="endpointDesc"
        ></TextArea>
        <TextArea
          :textTitle="filteringTitle"
          :textLinesArray="filteringText"
        ></TextArea>
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <EndpointCard cardTitle="ENDPOINTS" :endpoints="endpoints" />
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <Attribute :attributes="attributes" :endpoint="responseTitle" />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <ResponseCard :response="response" :cardTitle="responseCodeTitle" />
        </div>
      </v-col>
    </v-row>
    <v-row ref="POST" no-gutters color="transparent" elevation="0">
      <v-col class="left-column">
        <TextArea
          textTitle="Post a FitterHour"
          :textLinesArray="postFitterHourDesc"
        ></TextArea>
        <Attribute
          :attributes="hourRegistrationDTO"
          endpoint="HourRegistrationDTO"
        />
      </v-col>
      <v-col class="right-column">
        <div style="padding-top: 20px">
          <PostExample
            :postExample="fitterHourPostExample"
            cardTitle="POST /api/v3.0/fitterhours"
          />
        </div>
        <div style="padding-top: 20px">
          <ResponseCard
            :response="debtorCreateResponse"
            cardTitle="RESPONSE."
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import EndpointCard from "../../components/content/EndpointCard.vue";
import Attribute from "../../components/request/Attribute.vue";
import TextArea from "../../components/content/TextArea.vue";
import ResponseCard from "../../components/request/ResponseCard.vue";
import PostExample from "../../components/request/PostExample.vue";

const endPointName = "FitterHours";
const ekURL = "/Accountancy/FitterHours";

export default {
  components: {
    EndpointCard,
    Attribute,
    TextArea,
    ResponseCard,
    PostExample,
  },
  created() {
    this.$root.$on("scrollToEndpoint", (ref) => {
      var element = this.$refs[ref];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    });
  },
  data() {
    return {
      hourRegistrationDTO: [
        {
          name: "FitterHourID",
          type: "int",
          desc: "Unique identifier of the registrated hour.",
          default: "''",
          required: false,
        },
        {
          name: "date",
          type: "Datetime",
          desc: "The date the hour-registration was posted.",
          default: "''",
          required: true,
        },
        {
          name: "projectID",
          type: "Integer",
          desc: "Unique identifier project that the hours are registered on to.",
          default: "''",
          required: true,
        },
        {
          name: "fitterCategoryID",
          type: "Integer",
          desc: "Unique identifier of fitter category.",
          default: "''",
          required: true,
        },
        {
          name: "count",
          type: "Double",
          desc: "The amount of hours being registered.",
          default: "''",
          required: true,
        },
        {
          name: "hourRate",
          type: "Double",
          desc: "The hour rate of the hour registration.",
          default: "''",
          required: true,
        },
        {
          name: "note",
          type: "String",
          desc: "Note on the hour registration. Usually what has been done.",
          default: "''",
          required: false,
        },
      ],
      postFitterHourDesc: [
        "Alle POST kald i E-Komplets Ekstern API skal postes i lister, således man kan oprette flere ad gangen.",
        'Alle til API\' er pakket ind i en <a href="/api/docs/response" target="_blank">E-Komplet ResponseDTO</a>.',
      ],
      fitterHourPostExample: [
        {
          fitterHourID: 0,
          date: "2021-10-17T22:14:58.111Z",
          fitterID: 0,
          projectID: 0,
          fitterCategoryID: 0,
          count: 0,
          hourRate: 0,
          note: "string",
        },
      ],
      filteringTitle: "Filtrering",
      filteringText: [
        "På dette endpoint kan man påføre filtrering med parameterne, <code>fromDate</code> og <code>toDate</code>.",
      ],
      responseTitle: `The ${endPointName} Object`,
      responseCodeTitle: `${endPointName.toUpperCase()} RESPONSE. E-KOMPLET URL: ${ekURL}`,
      endPointName: endPointName,
      endpointDesc: [
        "FitterHours endpointet dækker over montørtimer. På endpointet kan man udtrække og indsætte montørtimer i E-Komplet systemet.",
        "Nedenstående kan man læse om hvilke felter man kan trække ud, samt hvordan man indsætter en montørtime igennem API'et.",
      ],
      endpoints: [
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
        {
          verb: "GET ",
          path: `/api/v3.0/${endPointName.toLowerCase()}/:id`,
        },
        {
          verb: "POST",
          path: `/api/v3.0/${endPointName.toLowerCase()}`,
        },
      ],
      response: [
        {
          ID: 6686,
          FitterReferenceNumber: "121212",
          FitterSalaryID: "",
          FitterName: "Lauris ",
          ProjectReference: "67935",
          ProjectDescription: null,
          ProjectName: "test af timereg tilb. i tid",
          ProjectResponsible: "Rikke  B. Bakken",
          WorkAddress: "Vestergade 4",
          WorkZip: "4000",
          CategoryReference: "100",
          CategoryName: "Montørtimer",
          WorkTypeId: 0,
          Note: null,
          Date: "2018-10-19T22:00:00Z",
          Hours: 1.0,
          HourRate: 195.0,
          PieceWork: 195.0,
          SocialTaxes: 93.6,
          SocialTaxesInPercent: 48.0,
          Expenses: 288.6,
          ProjectID: 7110,
          ProjectGroupName: "Alm. spjæld VVS",
          IsIntern: false,
          IsComplaint: false,
          SalaryPeriodTypeName: "Ingen lønperiode",
          IsMobile: false,
          ApprovedBy: "admin",
          ApprovedDate: "2018-11-05T15:06:55.977Z",
          RessourceGroups: null,
          DebtorReference: 44332267,
          DebtorName: "Tulle Jensen",
          DebtorFinancialAccountID: 3196,
          RessourceGroupString: "Murer",
          Department: "Vejle",
          Carrier: null,
          Purpose: null,
          Year: 2018,
          Month: 10,
          FitterCategoryType: "Arbejde",
          IncludeIllness: false,
          Unit: "TIMER",
          UnitID: 39,
          HoursOriginalValue: null,
          SortingKey: null,
          BasisTotalHours: 1.0,
          OvertimeTotalHours: 0.0,
          FitterHourWorkTypeOtherTotalHours: 0.0,
          BasisTotalCost: 288.6,
          OvertimeTotalCost: 0.0,
          FitterHourWorkTypeOtherTotalCost: 0.0,
          WorkType: 0,
          DateRangeStartDate: "2018-09-19T22:00:00Z",
          DateRangeEndDate: "2018-10-20T21:59:59Z",
        },
      ],
      attributes: [
        {
          name: "ID",
          desc: "Unique identifier of the fitter hour.",
        },
        {
          name: "FitterReferenceNumber",
          desc: "Reference number to identify the fitter.",
        },
        {
          name: "FitterSalaryID",
          desc: "",
        },
        {
          name: "ProjectReference",
          desc: "Reference of the project associated with the fitter hour.",
        },
        {
          name: "WorkTypeId",
          desc: "",
        },
        {
          name: "ProjectID",
          desc: "Unique identifier of the project that the fitter is attaching hours to.",
        },
        {
          name: "UnitID",
          desc: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>